.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #214a32 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-wrapper select {
  padding: 10px;
  margin: 0 auto;
  width: 300px;
  float: left;
}

#multiselectContainerReact {
  /* background: #FFF; */
  /* padding: 10px; */
  padding-top: 5px;
  /* border: 1px solid #CCC; */
}
/* .ant-menu.ant-menu-dark .ant-menu-item-selected, */
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
._7ahQImyV4dj0EpcNOjnwA {
  /* background-color: #17703c !important; */
  /* background-color: #214a32 !important; */
  /* background-color: #fff !important;
  color: #000 !important; */
  /* background-color: #fff !important;
  color: #000 !important; */
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  /* background: #214a32 !important; */
  background: #3f51b5 !important;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
  /* background: #214a32 !important; */
  background: #3f51b5 !important;
}

.MuiRadio-root {
  /* color: #23703c !important; */
  color: #3f51b5 !important;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
  padding-bottom: 0px !important ;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
._7ahQImyV4dj0EpcNOjnwA {
  /* background-color: #ffffff !important;
  color: #214a32 !important; */
  background-color: #fff !important;
  color: #3f51b5 !important;
  border-radius: 4px !important;
  border: 1px solid #3f51b5;
  position: relative;
}

._7ahQImyV4dj0EpcNOjnwA {
  padding: 4px 10px;
  background: #0096fb;
  margin-right: 20px !important;
  margin-bottom: 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  /* color: #FFF !important; */
  font-size: 13px;
  color: #fff;
  white-space: nowrap;
}

.ant-col.ant-col-10.ant-col-lg-12 div.ant-col {
  float: left;
  width: 40%;
}

.time-filter .searchBox {
  display: none !important;
}
.ant-col.ant-col-10.ant-col-lg-12 {
  margin-bottom: 0px !important;
}

.save-view {
  /* background-color: #fff; */
  background: transparent;
  color: #214a32 !important;
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  position: absolute;
  top: 65px;
  right: 100px;
  cursor: pointer;
  z-index: 1000;
  font-size: 30px;
}

.save-funnel,
.save-funnel:focus {
  /* background-color: #fff; */
  /* background-color: #283688;
    color: #FFF !important;
    border-radius: 5px; 
    border: none;
    padding: 10px 20px;
    position: absolute;
    top: 7px;
    right: 30px;
    cursor: pointer;
    z-index: 1000;
    font-size: 20px; */

  text-align: center;
  /* background-color: #fff; */
  background-color: #283688;
  color: #fff !important;
  border-radius: 35px;
  border: none;
  height: 50px;
  line-height: 50px;
  width: 50px;
  /* padding: 10px 13px; */
  position: absolute;
  top: 7px;
  right: 30px;
  cursor: pointer;
  z-index: 1000;
  font-size: 20px;
}

header {
  margin-bottom: 15px;
}

.display-view {
  /* background-color: #fff; */
  background: transparent;
  color: #214a32 !important;
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  position: absolute;
  top: 65px;
  right: 42px;
  cursor: pointer;
  z-index: 1000;
  font-size: 30px;
}

.bg-with-spacing {
  background-color: #fff;
  padding: 10px;
}

.clearFix {
  clear: both;
}

/* .card [data-background-color="purple"] {
  background: linear-gradient(60deg, #bbceda, #99b6c8); }

.card [data-background-color="red"] {
  background: linear-gradient(60deg, #f55a4e, #f32c1e); }

.card [data-background-color="green"] {
  background: linear-gradient(60deg, #79bd86, #57ad68); }

.card [data-background-color="blue"] {
  background: linear-gradient(60deg, #dda16f, #d38745); }

.card [data-background-color="orange"] {
  background: linear-gradient(60deg, #e9b056, #e39a28); } */

/* .color-1 {
    background: linear-gradient(60deg, #e9b056, #e39a28);
     }
  
  .color-2 {
    background: linear-gradient(60deg, #f55a4e, #f32c1e); }
  
  .color-3 {
    background: linear-gradient(60deg, #79bd86, #57ad68); }
  
  .color-4 {
    background: linear-gradient(60deg, #dda16f, #d38745); }
  
  .color-5 {
    background: linear-gradient(60deg, #bbceda, #99b6c8);
     } */

/* .bg-with-spacing h3{
  color: #FFF;
} */
/* .color-1 {
       background-color: #ffa600;
     }

     .color-1 ._2iA8p44d0WZ-WqRBGcAuEV  {
      border-bottom: 1px solid #ffa600 !important;
     }

     .color-2 {
      background-color: #ff7c43;
    }

    .color-3 {
      background-color: #f95d6a;
    }

    .color-4 {
      background-color: #d45087;
    }

    .color-5 {
      background-color: #a05195;
    }

    .color-6 {
      background-color: #665191;
    }

    .color-7 {
      background-color: #2f4b7c;
    }


    .color-8 {
      background-color: #003f5c;
    } */

.showHide,
.savedView {
  float: left !important;
  margin-right: 10px;
  padding: 10px;
  background-color: #3f50b4;
  color: white;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
}
.showHide:focus,
.savedView:focus {
  background-color: #3f50b4;
  color: white;
  border: 0px;
  border-radius: 5px;
}
.viewsList {
  margin-right: 10px;
  padding: 10px;
  color: #3f50b4;
  background-color: white;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.viewsList:focus {
  background-color: #3f50b4;
  color: white;
  border: 0px;
  border-radius: 5px;
}

.saveLabel {
  margin-left: 10px;
  padding: 10px;
  background-color: #3f50b4;
  color: white;
  border: 0px;
  border-radius: 5px;
}

.site-card-wrapper {
  margin: 20px 10px;
}

hr {
  border-top: 1px solid #dedede !important;
}

.site-card-wrapper {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 10px 20px 30px;
  margin-bottom: 20px;
}

.space-adjust {
  margin-left: -6px !important;
}
.ant-card {
  cursor: pointer;
}
/* .ant-card:hover {
    background: #efefef;
    box-shadow: 5px 10px red;
  } */

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: #3f51b4 !important;
  color: #fff !important;
  border-color: #3f51b4 !important;
}

.ant-layout-header {
  display: none;
}

.ant-layout-content {
  margin-top: 0px;
}

.fyrYpc {
  outline: none;
}

.eMIUUJ,
.reactour__helper {
  border-radius: 5px !important;
  padding-top: 35px !important;
}

.eTpeTG {
  position: absolute;
  top: 11px !important;
  right: 17px !important;
  width: 12px !important;
  height: 11px !important;
  color: #5e5e5e;
}

.sc-EHOje,
.sc-htoDjs,
.ant-btn-primary {
  background: #3f51b5 !important;
}

.MuiTextField-root {
  float: left;
  width: 100%;
}

.ant-modal-header {
  border-radius: 5px 5px 0 0 !important;
}

.ant-modal-footer {
  border-radius: 0 5px 5px !important;
}

.clearFix {
  clear: both;
}

.saved-filter-card:hover .ant-card-actions {
  background-color: #283688 !important;
  color: #fff !important;
}

.saved-filter-card:hover .anticon {
  color: #fff !important;
}

.full-width {
  width: 100%;
}

._2iA8p44d0WZ-WqRBGcAuEV input {
  width: 300px;
}

/* Manager Score Board */

/* .date_range {
  position: absolute;
  top: 30px;
  right: 2%;
} */

.top-widgets .ant-card-head {
  border-bottom: none !important;
}
.top-widgets {
  margin: 5px 5px;
}

.top-widgets .ant-col {
  float: left !important;
  /* flex: 0 0 15% !important;
  padding: 5px !important; */
}

.top-widgets .ant-row-middle {
  float: left;
}

.top-widgets .ant-row-middle ~ .ant-row-middle {
  float: right !important;
  opacity: 0.5 !important;
}

.top-widgets
  .ant-row-middle
  .ant-statistic-content
  ~ .ant-row-middle
  .ant-statistic-content {
  font-size: 10px !important;
}

/* .top-widgets .ant-statistic-content ~ .ant-statistic-content { 
  font-size:10px !important;
}

.ant-statistic-content-value-decimal {

} */

.top-widgets .ant-col .ant-row-center:nth-child(2) .ant-statistic-content {
  font-size: 16px !important;
  padding-top: 18px;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  background: #9493dc !important;
  color: #fff !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  /* background: #6a6b6e !important;
  color: #fff !important;
  border-color: #6a6b6e !important; */

  background: #9493dc !important;
  color: #fff !important;
  border-color: #9493dc !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #9493dc !important;
  border-color: #9493dc !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #9493dc !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #9493dc;
}

.ant-radio:hover .ant-radio-inner {
  border-color: r#9493dced;
}

.over-all-heading {
  float: left;
}

.main-score-card-title {
  width: 100%;
  font-size: 25px;
}

.main-score-card-sub-title {
  width: 100%;
}

.outer_wrapper {
  position: relative;
}

._7ahQImyV4dj0EpcNOjnwA {
  padding: 0px !important;
}

.outer_wrapper .wrapper_text {
  border-color: #000;
  color: #403e3e;
  padding-right: 13px;
}

.inner_wrapper {
  float: left;
  background: #ccc;
  color: #212121 !important;
  padding: 5px 10px;
  background: #3f51b5;
  line-height: 18px;
  margin-right: 8px;
}

.wrapper_text {
  line-height: 25px;
}

._3crOX-etLxsZ8OgjhYCvt7 {
  font-size: 19px !important;
}

/* ._7ahQImyV4dj0EpcNOjnwA:hover{
  opacity: 0.5;
} */

.Lineofbusiness {
  color: #d81dd1 !important;
  border-color: #d81dd1 !important;
  font-weight: bold;
}

.Productcategory {
  color: #9daf22 !important;
  border-color: #9daf22 !important;
  font-weight: bold;
}

.Documenttype {
  color: #357450 !important;
  border-color: #357450 !important;
  font-weight: bold;
}

.Serviceplan {
  color: #e6884a !important;
  border-color: #e6884a !important;
  font-weight: bold;
}

.DT.wrapper_text {
  color: #357450;
}
.DT_outer_wrapper {
  border-color: #357450 !important;
  color: #357450 !important;
}
.DT_outer_wrapper .inner_wrapper {
  background: #357450 !important;
}

.timeSlice .undefined_inner_wrapper,
.dateRange .undefined_inner_wrapper {
  display: none;
}
.timeSlice .wrapper_text,
.dateRange .wrapper_text {
  padding: 0 5px;
}

.custom-ui .undefined_outer_wrapper {
  padding: 2px 5px !important;
  width: 100%;
  /* float: left; */
  /* background-color: #CCC !important; */
  border: 1px solid #ccc;
}

.custom-ui ._1cgu0CqbPSvU9G_sHmk1wv {
  position: absolute;
  right: 23px;
  top: 20px;
  transform: translateY(-50%);
}

.display-by-group {
  margin-top: 5px !important;
}

.custom-ui .outer_wrapper .wrapper_text {
  color: #666 !important;
}
/* 

  .undefined.wrapper_text {
    padding: 2px 5px !important;
    width: 100%;
    float: left;
    border: #CCC !important;
} */

._3N0WR2P3JAHZIXKMgKZeiH {
  display: none !important;
}

/* .Hidethis {
  display: none !important;
}   */

.undefined.wrapper_text {
  padding: 2px 5px !important;
}

/* span.chip {
  padding: 5px 10px !important;
} */

.Node\.module_node_2sWtP > rect:hover {
  stroke: #ffffff !important;
}

.MuiAppBar-colorPrimary {
  margin-bottom: 0px;
}

.MuiBox-root {
  /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
}

.MuiAppBar-colorDefault {
  margin-bottom: 0px;
}

.sec-title {
  margin-bottom: 0px !important;
  font-size: 15px;
}

.fullWidth {
  width: 100%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.filter-by-group-new {
  padding-top: 5px !important;
  /* padding-bottom: 15px !important; */
}
.filter-by-group-new
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.filter-by-group-new .ant-radio-button-wrapper,
.filter-by-group-new .ant-radio-button-wrapper:hover {
  background: none !important;
  border: none !important;
  color: #666 !important;
  box-shadow: none;
}

.filter-by-group-new .ant-radio-button-inner {
  display: none !important;
}

/* .filter-icon.fa {
  background: #7159da !important;
} */

.bg-icon span.filter-icon {
  width: 15px;
  height: 15px;
  float: left;
  margin-top: 8px;
  margin-right: 5px;
  /* border-radius: 5px; */
  background: #f3f3f3;
}

.bg-icon .ant-radio-button-wrapper-checked {
  font-weight: bold;
}

.ant-radio-button-wrapper-checked .filter-icon.fa {
  background: #7159da !important;
}

.ant-radio-button-wrapper-checked .filter-icon.bpc {
  background: #a52c2a !important;
}

.ant-radio-button-wrapper-checked .filter-icon.cu {
  background: #104fde !important;
}

.ant-radio-button-wrapper-checked .filter-icon.lb {
  background: #d81dd1 !important;
}

.ant-radio-button-wrapper-checked .filter-icon.pc {
  background: #9daf22 !important;
}

.ant-radio-button-wrapper-checked .filter-icon.dt {
  background: #357450 !important;
}

.ant-radio-button-wrapper-checked .filter-icon.sp {
  background: #e6884a !important;
}

.ant-radio-button-wrapper-checked .filter-icon.all {
  background: #666 !important;
}

.filter-by-group-new {
  margin-bottom: 10px;
}

.bg-icon.ant-radio-button-wrapper:not(:first-child)::before {
  background: transparent !important;
}

.ant-radio-button-wrapper:focus-within,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none !important;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  border: none !important;
}

.filter-bg.color-1 {
  padding-bottom: 0px;
  border-radius: 3px;
  padding: 10px 10px 0px 10px;
  border: 1px solid #adaaaa75;
  min-height: 105px;
}

.borderTop {
  border-top: 1px solid #f3f3f3;
  background: #f3f3f347;
}

.clerFix {
  clear: both !important;
}

/* footer section */

.tabFooter {
  border-top: 1px solid #66666638;
  float: left;
  width: 100%;
  margin-top: 15px;
  background: #cccccc12;
  padding-bottom: 10px;
  padding-top: 5px;
}

.savedView {
  margin-top: 5px;
  float: right !important;
  padding: 8px 12px !important;
  font-size: 14px;
  background: #214a32 !important;
}

.MuiBadge-colorSecondary {
  background: #214a32 !important;
}

.hide {
  display: none !important;
}

#filterContainer-title {
  margin-top: 5px;
}

.optionContainer li {
  font-size: 12px;
}

.MuiTab-root {
  min-height: 70px !important;
}

.time-filter ._2iA8p44d0WZ-WqRBGcAuEV._2hO6ncpWlUNK05lxQ9_UtY {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.searchBox::-webkit-input-placeholder {
  /* Edge */
  font-size: 12px;
  color: #ccc;
}

.searchBox:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 12px;
  color: #ccc;
}

.searchBox::placeholder {
  font-size: 12px;
  color: #ccc;
}

#standard-required-label {
  font-size: 12px;
}

.searchBox {
  padding-left: 10px;
}

#wrapped-tabpanel-0,
#wrapped-tabpanel-1,
#wrapped-tabpanel-2 {
  width: 100%;
}

.MuiPaper-root {
  background: transparent !important;
}
.MuiButtonBase-root,
.MuiAccordionDetails-root {
  padding: 0px !important;
}

.MuiAccordionSummary-expandIcon {
  position: absolute;
  right: 25px;
}

.MuiAccordionSummary-content {
  margin: 0px -13px 0px 0px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 270px !important;
  }
}

.tabFooterContainer {
  width: 40%;
  float: right;
}

.tabFooterContainerLeft {
  float: right;
  max-width: 400px;
}

.tabFooterContainerRight {
  float: right;
}
.MuiIconButton-root {
  position: absolute;
  top: -35px !important;
  z-index: 2;
}

.MuiAccordionSummary-root,
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
  height: 1px;
}

.MuiAccordion-root.Mui-expanded:last-child {
  margin-top: 0px;
}

.cateCountWidget {
  float: left;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
}

.cateCountWidget li {
  list-style: none;
  float: left;
  margin: 0px 5px;
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  height: 30px;
  color: #666;
}

.cateCountWidget li span {
  width: 25px;
  line-height: 15px;
  text-align: center;
  height: 25px;
  /* background: #ccc; */
  /* border: 1px solid #666; */
  border-radius: 20px;
  padding: 5px;
  font-size: 17px;
  margin-top: -4px;
  margin-left: 5px;
  margin-right: -2px;
  float: right;
  color: #000 !important;
}

.cateCountWidget li {
  position: relative;
}

.cateCountWidget li i svg {
  font-size: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  background: red;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.cateCountWidget li i img {
  font-size: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

[class^="icon_"]:before,
[class*=" icon_"]:before {
  color: #666;
}

/*** Facility ***/

.cateCountWidget li.bg1 span {
  /* background:#ded5ef !important; */
  border-color: #7159da !important;
}

.Facility {
  color: #7159da;
  /* background:#7159da4d !important; */
  border-color: #7159da !important;
  font-weight: bold;
}

/* .FA.wrapper_text {
  color: #214a32;
} */

.wrapper_text {
  line-height: 27px;
}
.FA_outer_wrapper {
  border-color: #d5cdf4 !important;
  color: #7159da !important;
}

.FA_outer_wrapper .inner_wrapper {
  color: #7159da !important;
  background: #7159da4d;
  border-right: 1px solid #d5cdf4 !important;
}

/* .cateCountWidget li.bg1 span { 
  background:#7159da4d !important;
  border-color: #d5cdf4 !important;
} */

/** CC **/

/* .cateCountWidget li.bg2 span { 
  background:#a52c2a66 !important;
  border-color: #a52c2a !important;
} */

.Bpcategory {
  color: #a52c2a !important;
  border-color: #a52c2aa3 !important;
  font-weight: bold;
}

.CC.wrapper_text {
  /* color: #a52c2a; */
}

.CC_outer_wrapper {
  border-color: #d0918f !important;
}

.CC_outer_wrapper .inner_wrapper {
  background: #a52c2a85 !important;
}

/*** Cu ***/

/* .cateCountWidget li.bg3 span { 
  background:#104fde5c !important;
} */

.Customername {
  color: #104fde !important;
  border-color: #104fde !important;
  font-weight: bold;
}

.Cu.wrapper_text {
  /* color: #104fde; */
}
.Cu_outer_wrapper {
  border-color: #a8bff3 !important;
  /* color: #104fde !important; */
}
.Cu_outer_wrapper .inner_wrapper {
  background: #104fde5c !important;
}

/* Line of Business */

.LB.wrapper_text {
  /* color: #d81dd1; */
}
.LB_outer_wrapper {
  border-color: #d81dd14d !important;
  color: #d81dd1 !important;
}
.LB_outer_wrapper .inner_wrapper {
  background: #d81dd14d !important;
}

/** Product Category **/

/* .PC.wrapper_text {
  color: #9daf2275;
} */
.PC_outer_wrapper {
  border-color: #9daf2275 !important;
  color: #9daf22 !important;
}
.PC_outer_wrapper .inner_wrapper {
  background: #9daf2275 !important;
}

/** Product Doc Type **/

.DOC_outer_wrapper {
  border-color: #22af6f75 !important;
}
.DOC_outer_wrapper .inner_wrapper {
  background: #22af6f75 !important;
}

/** Serive Plan **/

/* .SP.wrapper_text {
  color: #e6884a;
} */
.SP_outer_wrapper {
  border-color: #e6884aab !important;
  color: #e6884aab !important;
}
.SP_outer_wrapper .inner_wrapper {
  background: #e6884aab !important;
}

.full-width .ant-col-24 {
  padding-left: 0px;
}

.filter-bg .icon_cancel {
  background: url(./data/close-icon.png) no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -7px;
  top: -1px;
}

.filter-bg ._1ruIhoPvyfSHg9LQB0CI6p:before {
  content: "";
}

.customTab {
  background: #fff;
  padding-top: 20px;
  height: 70px;
  position: relative;
  /* z-index: 1; */
}

.white-bg {
  background: #fff;
}

/* .white-bg.spacing {
  background: #FFF;
  padding: 10px;
}

.date_range {
  margin: 10px;
  float: left;
} */

.mb-sm {
  margin-bottom: 15px;
}

.previousInclude {
  float: left;
  margin-top: 0px;
}

.filter-wrapper {
  padding: 10px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 2px 0 0 2px;
}

.displayByOptions {
  float: left;
  width: 100%;
}

.displayByOptions .ant-radio-button-wrapper {
  float: left;
  width: 16.66% !important;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .MuiGrid-root {
    padding: 0px !important;
  }
  .time-filter {
    margin-bottom: 15px !important;
  }
  .save-view {
    top: -6px;
    right: 146px;
  }
  .display-view {
    top: -6px;
    right: 83px;
  }
  .ant-layout-content {
    margin-top: 15px;
  }

  /* 
  .white-bg1.spacing {
    margin-bottom: 10px;
  } */
}

.filter-wrapper {
  min-height: 75px;
}

.displayByOptions {
  margin-top: 10px;
}

.filter-bg.color-1 {
  margin-bottom: 15px;
}

.recharts-legend-wrapper {
  display: none !important;
}

.recharts-legend-wrapper {
  display: none !important;
}

.makeStyles-paper-2 {
  height: 800px;
  width: 1000px !important;
  overflow-y: scroll;
}

/* For Top widget Change */

/* .ant-col.active .ant-card {
   background: #9493dc;
}

.ant-col.active .ant-card-head-title {
  color: #FFF !important;
}

.ant-col.active .ant-card-head-title, .ant-col.active .ant-statistic-content-value {
  color: #FFF !important;
}

.ant-statistic-content-value {
  white-space: pre;
  } */

.ant-card,
.bg-with-spacing {
  border: 1px solid #70707080 !important;

  /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */

  /* box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 5px; */
  margin-top: -1px !important;
}

.top-widgets .ant-col .ant-row-center:nth-child(1) .ant-statistic-content {
  font-size: 51px !important;
  padding-top: 8px;
  font-family: calibri;
}

.ant-layout {
  background-color: #fff !important;
}

@media only screen and (min-width: 601px) {
  .ant-card,
  .bg-with-spacing {
    margin-bottom: 25px !important;
  }
}

@media only screen and (max-width: 600px) {
  .ant-row-center.ant-row-middle {
    text-align: center;
  }

  .top-widgets .ant-row-middle ~ .ant-row-middle {
    float: none !important;
    opacity: 0.5 !important;
    width: 100%;
  }

  .ant-row.ant-row-center.ant-row-middle {
    width: 100%;
  }

  .ant-row {
    margin: 0 !important;
    padding: 0 !important;
  }
}

/*default version*/
@font-face {
  font-family: "calibribold";
  src: url("font/Calibri Bold.TTF");
  font-weight: bold;
}
/*bold version*/
@font-face {
  font-family: "calibribolditalic";
  src: url("font/Calibri Bold Italic.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "calibriitalic";
  src: url("font/Calibri Italic.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: "calibrilight";
  src: url("font/Calibri Light.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: "calibrilightitalic";
  src: url("font/Calibri Light Italic.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: "calibri";
  src: url("font/Calibri Regular.ttf");
  font-weight: normal;
}

body {
  font-family: "calibrilight";
  background-color: #f8f8f8 !important;
}

.text-typo-2 {
  font-size: 20px;
  font-family: "calibrilight";
}

.big-number {
  font-size: 51px !important;
  color: #707070 !important;
  font-family: "calibrilight";
}

.top-widgets .ant-card-head-title {
  color: #707070;
  font-size: 22px;
  font-family: "calibrilight";
}

.ant-card-head {
  padding: 0 12px;
}

.small-word {
  color: #707070 !important;
  font-size: 16px !important;
  display: block;
  clear: both;
  font-family: "calibri" !important;
}

.ant-layout {
  background-color: #f8f8f8 !important;
}

.scheduled-btn,
.scheduled-btn:focus,
.scheduled-btn:hover,
.customer-btn,
.customer-btn:focus,
.customer-btn:hover {
  margin-right: 30px;
  outline: none;
  border: none;
  color: #707070;
  background: white;
  font-size: 1 rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.plan-btn,
.plan-btn:focus,
.plan-btn:hover {
  background: white;
  color: #707070;
  outline: none;
  border: none;
  font-size: 1 rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.view-active,
.view-active:hover,
.view-active:focus {
  background: #307a49;
  color: white;
}

.view-opt {
  margin-left: 45px;
}

@media (max-width: 600px) {
  .view-opt {
    margin-left: 0px;
  }

  .backicon {
    right: 20px;
  }

  .backiconMiss {
    right: 30px;
  }

  .scheduled-btn,
  .scheduled-btn:focus,
  .scheduled-btn:hover,
  .customer-btn,
  .customer-btn:focus,
  .customer-btn:hover {
    margin-right: 20px;
  }
}

.back-icon {
  font-size: 50px;
  background: #307a49;
  color: white;
  border-radius: 70px;
  margin-left: 15px;
  margin-top: 4px;
}

.ant-collapse-header {
  background: #307a49;
  color: white !important;
}

.ant-collapse.ant-collapse-icon-position-left {
  margin-bottom: 15px;
}

.missed {
  background: #f8f8f8;
}

.ant-card-bordered {
  border: 0px !important;
}

.ant-card {
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 10px !important;
  border: none !important;
  margin-bottom: 35px !important;
}

.ant-collapse-content-box {
  padding: 0px !important;
}

.missHeader {
  padding: 10px 20px;
  color: black;
  font-weight: bold;
  background: #f8f8f8;
}

@media (max-width: 760px) {
  .ant-card-body {
    margin-bottom: 20px;
  }
}

.ant-collapse-extra {
  position: absolute;
  top: 32%;
  right: 20px;
}

.status-chart {
  clear: both;
  width: 60%;
  border: 1px solid #ccc;
  position: a;
  border-radius: 3px;
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 5x !important;
  border: none !important;
  padding: 20px !important;
  margin-top: 20px !important;
  margin: 20px auto 10px auto;
  float: none;
}

@media only screen and (max-width: 800px) {
  .status-chart {
    float: left;
    width: 100%;
  }
}

.plan-btn,
.plan-btn:hover,
.scheduled-btn,
.scheduled-btn:hover {
  border: 1px solid #307a49;
}

.mob-filter.heading {
  color: #000 !important;
}

.task-button {
  padding: 20px 30px;
  font-size: 20px;
}
.small-text {
  color: #706f6f;
}

.top-widgets .ant-row-middle {
  float: left;
  width: 100%;
}

.form-group {
  padding: 10px;
  padding-bottom: 0px;
}

/* .form-control {
  padding: 15px !important;
  height: 30px;
} */

.disable-btn,
.disable-btn:hover,
.disable-btn:focus {
  background: #cacaca;
  border: #cacaca;
}

.primary-color {
  color: #307a49;
}
.position-content {
  position: relative;
  width: 100%;
}

.position-content:hover .big-number {
  color: #307a49 !important;
}

.position-content:hover .task-list-btn {
  background: #307a49 !important;
  color: #fff !important;
}

.task-list-btn {
  position: absolute;
  padding: 10px 20px;
  border: 1px solid #307a49;
  border-radius: 5px;
  top: 50%;
  right: 10px;
  margin-top: -25px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background: #307a49;
}

.big-number_2 {
  font-size: 20px;
}

.form-control {
  border: 1px solid #307a49 !important;
  height: 40px;
}

.form-balel {
  font-size: 15px;
  color: #000 !important;
}

.btn-submit,
.btn-submit:hover {
  padding: 10px 20px;
  font-size: 18px;
}
.small-text.primary {
  color: #307a49 !important;
}

.success-section {
  width: 100%;
  text-align: center;
  padding: 50px;
}
.nav-button {
  background: #307a49 !important;
  color: #fff !important;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
}

.btn-cancel {
  background: #fff !important;
  color: #307a49 !important;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: none;
  border: 1px solid #307a49;
  padding: 10px 20px;
  font-size: 18px;
}

.btn-cancel:hover {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.task-title .small-text {
  font-size: 15px !important;
}

.mob-filter.heading {
  color: #307a49 !important;
  font-weight: bold;
}

.show-mobile {
  display: none;
}

.full-width {
  float: left;
  width: 100%;
}

.list-head-title {
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
}
.list-head-sub-title{
  color: rgb(112, 112, 112);
  font-size: 16px;
}


.page-header{
  background: #FFF;
  padding: 10px;
  margin-bottom: 20px !important; 
  width: 100%;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.ant-modal-close {
  display: none;
}

@media only screen and (max-width: 800px) {

  body {
    background: #f8f8f8 !important;
  }
  .page-header{
    background: #FFF;
    padding: 10px;
    margin-bottom: 20px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
  }


  .ant-layout-content {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

  .filter-btn {
    float: left;
    width: 30%;
    margin: 0px 1.5%;
    text-align: center;
    padding: 10px;
    font-size: 18px;
  }

  .filter-btn.view-active {
    padding: 10px;
    background: #e0e0e0;
    color: #000;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
  }

  .filter-btn.adjust {
    float: left;
    width: 40% !important;
    margin: 0px 5% !important;
  }

  .border-bottom {
    border-bottom: 1px solid #666;
  }

  .widget-space {
    padding-top: 20px;
    border-bottom: 1px solid #666;
    padding-bottom: 20px;
  }

  .widget-header {
    background: #fff;
  }

  .tab-btn {
    float: left !important;
    width: 50% !important;
    border: none;
    background: transparent;
    padding: 20px 10px;
    border-bottom: 2px solid #ccc;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    /* margin-top: 10px; */
  }

  .tab-btn.view-active {
    background: transparent;
    color: #307a49 !important;
    border-bottom: 2px solid #307a49;
  }

  .show-mobile {
    display: block;
  }

  .show-desktop {
    display: none;
  }

  .big-number_2 {
    font-size: 16px !important;
  }

  .position-content {
    padding-bottom: 70px;
    width: 100%;
  }

  .task-list-btn {
    bottom: 0px !important;
    left: 0px;
    top: auto;
  }
  .big-number.primary-color {
    line-height: 50px;
  }

  .form-group {
    text-align: left;
  }

  .btn-submit {
    width: 100%;
  }

  .top-heading {
    font-size: 26px !important;
    text-align: center !important;
  }

  .btn-cancel {
    margin-top: 10px;
    width: 100%;
  }
}
