body {
  background-color: #FFFFFF !important;
  
  /* background-color: #f0f2f5 !important; */
  
  font-size: 15px !important; 
}


.button-group {
  float: left;
  width: 100%;
  margin-top: 20px;
  /* border-top: 1px solid #efefef; */
  
}

.single-button {
  float: left;
  width: 49%;
  text-align: center;
  padding-top: 5px;
}

.border-right {
  border-right: 1px solid #efefef;
}

/* .btn {
  background-color: tomato;
  border: none;  
}  */

.btn-view {

}

.ant-card-actions .anticon{
  font-size: 18px;
}

.ant-btn-icon-only {
  width: 48px !important;
  height: 48px !important;
} 

.btn-icon{
  font-size: 20px;;
}

.ant-card-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}